import { createClient } from '@supabase/supabase-js'


console.log(process.env.REACT_APP_SUPABSE_URL)
console.log(process.env.REACT_APP_SUPABASE_KEY)

const supabaseUrl = process.env.REACT_APP_SUPABSE_URL
const supabaseKey = process.env.REACT_APP_SUPABASE_KEY
const supabase = createClient(supabaseUrl, supabaseKey)

export default supabase