import React, { useState } from 'react';
import { HiOutlineMenuAlt2 } from 'react-icons/hi'
import { IoClose } from 'react-icons/io5'
import logo from './logo.png'
import './input.css'
import './output.css'

function Sidebar(props) {
    const [animation, setAnimation] = useState('menuOpen');
    const [isOpen, setIsOpen] = useState(false);
    const [state, setState] = React.useState([
        {
            title: 'Instagram',
            isActive: 'true',
            link: '/instagram'
        },
        {
            title: 'Tiktok',
            isActive: 'false',
            link: '/tiktok'
        },
        {
            title: 'Facebook',
            isActive: 'false',
            link: '/facebook'
        },
        {
            title: 'Twitter',
            isActive: 'false',
            link: '/twitter'
        },
        {
            title: 'LinkedIn',
            isActive: 'false',
            link: '/linkedIn'
        },
        {
            title: 'Youtube',
            isActive: 'false',
            link: '/youtube'
        },
        {
            title: 'Blog Post',
            isActive: 'false',
            link: '/blogpost'
        },
        {
            title: 'Email',
            isActive: 'false',
            link: '/email'
        },
        {
            title: 'Radio Ads',
            isActive: 'false',
            link: '/radioad'
        },
        {
            title: 'Infographics',
            isActive: 'false',
            link: '/infographics'
        },
        {
            title: 'Press Release',
            isActive: 'false',
            link: '/pressrelease'
        }
    ])

    const logout = () => {
        sessionStorage.clear();
        window.location.reload();
    }

    const [showLogout, setShowLogout] = useState(false);

    React.useEffect(() => {
        const token = sessionStorage.getItem('uid');
        if (token === null || token === undefined) {
            setShowLogout(false);
        }
        else {
            setShowLogout(true);
        }
        if (localStorage.getItem('menu')) {
            window.location.href = localStorage.getItem('menu');
            localStorage.removeItem('menu');
        }
        console.log(showLogout);
    }, []);
    return (
        <>
            {/* mobile menu */}
            <div className="md:hidden flex flex-row justify-between items-center border-b border-gray-400/20 bg-parentPurple drop-shadow-md py-4 px-3 shrink-0">
                <button className="text-white" onClick={async () => {
                    setAnimation('menuOpen')
                    setIsOpen(true)
                }
                }>
                    <HiOutlineMenuAlt2 className="text-3xl text-myYellow" />
                </button>
                <a href="/">
                    <img src={logo} alt="logo" className="w-20" />
                </a>
                <div></div>
            </div>
            {/* mobile menu */}
            {isOpen && (
                <div className={`md:hidden menuOpen flex flex-col bg-black/20 backdrop-blur-2xl h-screen w-screen absolute py-12 px-4 top-0`}>
                    <div className="flex flex-row w-full justify-center items-center">
                        <IoClose className="text-white text-5xl" onClick={
                            () => {
                                setIsOpen(false)
                            }
                        }
                        />
                    </div>
                    <div className="flex flex-col h-full justify-center w-full overflow-y-auto ">
                        {state.map((item, index) => (
                            <>
                                <a
                                    href={item.link}
                                    className={`w-full py-3 px-4 flex items-center bg-opacity-80 text-myYellow hover:bg-myYellow/10 transition-all ease-linear rounded-md ${props.title === item.title ? 'bg-myYellow w-full hover:bg-gray-800 text-white rounded-md' : 'text-white'}`}
                                    key={index}
                                >
                                    <span className="text-lg font-semibold">{item.title}</span>
                                </a>
                            </>
                        ))}
                    </div>
                    {showLogout && (
                        <button
                            onClick={logout}
                            className=" bg-myYellow rounded-md w-full py-3 text-white">
                            Logout
                        </button>
                    )}
                </div>
            )}
            <div className=' flex min-h-screen flex-row w-screen'>
                <div className="hidden md:flex flex-col justify-between items-start bg-parentPurple border-r border-gray-400/20 drop-shadow-xl md:w-3/12 lg:w-1/6 py-4 px-3 shrink-0">
                    <a href="/">
                        <img src={logo} alt="logo" className="w-32" />
                    </a>
                    <div className='flex shrink-0 flex-col justify-start items-start w-full overflow-y-auto'>
                        {state.map((item, index) => (
                            <>
                                <a
                                    href={item.link}
                                    className={`w-full py-3 px-4 flex items-center bg-opacity-80 text-myYellow hover:bg-myYellow/10 transition-all ease-linear rounded-md ${props.title === item.title ? 'bg-myYellow w-full hover:bg-black text-white rounded-md' : 'bg-transparent text-myYellow'}`}
                                    key={index}
                                >
                                    <span className="text-xxs ">
                                        {item.title}
                                    </span>
                                </a>
                            </>
                        ))}
                    </div>
                    {showLogout ? (
                        <button
                            onClick={logout}
                            className=" bg-myYellow rounded-md w-full py-3 text-white">
                            Logout
                        </button>
                    ) : <div></div>}
                </div>
                <div className='flex flex-row h-full w-full'>
                    {props.abc}
                </div>
            </div>
        </>
    )
}

export default Sidebar