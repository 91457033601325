import Swal from "sweetalert2";

export const AlertBox = () => {
    Swal.fire({
        title: 'Success!',
        text: 'Copied to clipboard',
        icon: 'success',
        confirmButtonText: 'OK',
        confirmButtonColor: '#333333',
    })
}