import React from 'react'
import Header from './Header'

function Home() {
    return (
        <div className='flex flex-col items-center justify-center h-screen bg-parentPurple'>
            <Header />
        </div>
    )
}

export default Home