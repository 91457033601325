import React from 'react'
import Dropdown from './Dropdown'
import './App.css'
import { OpenAI } from "openai-streams";
import { AlertBox } from './AlertBox';
import Header from './Header';
import Login from './Login';
const apiKey = process.env.REACT_APP_OPENAI

function BlogPost() {
    const [value, setValue] = React.useState('')
    const [value1, setValue1] = React.useState("")
    const [usrInput, setUsrInput] = React.useState('')
    const [showLogin, setShowLogin] = React.useState(false)
    // add this to all pages
    const [result, setResult] = React.useState('')
    const [showInput, setShowInput] = React.useState(true)
    const [click, setClick] = React.useState(localStorage.getItem('click') || 0)

    const messagesArray = [
        {
            role: "system",
            content: ` Create a blog ${value !== null ? value : ''} with ${value1 !== null ? value1 : ''} tone of voice.
            Minimum word count: 1000
            ${usrInput !== null ? `Use the following idea: ${usrInput}` : ''}
            Be creative and write a post that will engage your audience. Add hashtags if needed. Also give picture ideas for the post. Add Headings and subheadings. Be creative and write a post that will engage your audience. Give 2-3 options for the post.`
        }
    ];
    const onClick = async () => {
        setClick(click + 1)
        localStorage.setItem('click', click)
        if(click === 1 && (sessionStorage.getItem('uid') === null || localStorage.getItem('uid') === undefined))
        {
            window.location.href = '/login'
            return
        }
            localStorage.setItem('value', value)
            localStorage.setItem('value1', value1)
            localStorage.setItem('usrInput', usrInput)
                setShowInput(false)
                const stream = await OpenAI(
                    "chat",
                    {
                        model: "gpt-3.5-turbo",
                        messages: messagesArray,
                    },
                    { apiKey: apiKey }
                );
                const res = new Response(stream);       // create stream response
                const reader = res.body.getReader();    // get reader from stream
                const decoder = new TextDecoder("utf-8");
                let data = "";
                let msg = [];
                // Read only content from the stream
                while (true) {
                    const { done, value } = await reader.read();
                    if (done) {
                        break;
                    }
                    data += decoder.decode(value);
                    setResult(data)
                }
    }

    React.useEffect(() => {
        setValue(localStorage.getItem('value'))
        setValue1(localStorage.getItem('value1'))
        setUsrInput(localStorage.getItem('usrInput'))
    }, [])

    const copy = () => {
        navigator.clipboard.writeText(result)
        console.log(result)
        AlertBox()
    }

    return (
        <div className='flex flex-col items-center w-full h-screen bg-parentPurple px-4'>
            <div className='flex flex-col items-center justify-center '>
                <h1 className='font-bold text-3xl tracking-wide text-myYellow'>
                    BlogPost
                </h1>
            </div>
            {showInput && (
                <div className='flex flex-col justify-center items-center h-full space-y-4 w-full md:w-3/4 lg:w-2/4'>
                    <textarea rows={1}
                        onChange={(e) => setUsrInput(e.target.value)}
                        placeholder='Enter the blog post idea.'
                        className='w-full p-4 rounded-md focus:outline-none focus:ring-2 focus:ring-myYellow/50 transition-all ease-in resize-none bg-myPurple/20 border border-myYellow text-myYellow placeholder:text-myYellow/60' />
                    <select
                        onChange={(e) => setValue(e.target.value)}
                        className='p-4 w-full focus:outline-none rounded-md border border-myYellow bg-myPurple/20 text-myYellow'>
                        <option value="Choose what you want to create">Choose what you want to create</option>
                        <option value='Create a list of ideas for blog posts'>
                            Create a list of ideas for blog posts
                        </option>
                        <option value='Create a blog Post'>
                            Create a blog Post
                        </option>
                    </select>
                    {value !== 'Create a list of ideas for blog posts' && (
                        <select
                            onChange={(e) => setValue1(e.target.value)}
                            className='p-4 w-full focus:outline-none rounded-md border border-myYellow bg-myPurple/20 text-myYellow'>
                            <option value1="Select tone of voice">Select tone of voice</option>
                            <option value="Professional/Formal">Professional/Formal</option>
                            <option value="Conversational">Conversational</option>
                            <option value="Inspiring">Inspiring</option>
                            <option value="Humorous/Fun">Humorous/Fun</option>
                            <option value="Empathetic">Empathetic</option>
                        </select>
                    )}
                    <button
                        onClick={onClick}
                        className='w-full bg-myYellow text-parentPurple font-bold rounded-md py-4 text-lg'>
                        Generate
                    </button>
                </div>
            )}
            {!showInput && (
                <div className='flex flex-col justify-center items-center h-full space-y-4 w-full md:w-3/4 lg:w-2/4'>
                    <h1 className='text-2xl font-bold text-myYellow'>
                        Generated Content
                    </h1>
                    <textarea rows={16}
                        defaultValue={result}
                        className='w-full p-4 rounded-md focus:outline-none focus:ring-2 focus:ring-myYellow/50 transition-all ease-in resize-none bg-myPurple/20 border border-myYellow text-myYellow placeholder:text-myYellow/60'
                        value={result} />
                    <div className='w-full flex flex-row items-center space-x-4 justify-center'>
                        <button
                            onClick={copy}
                            className='w-full border border-myYellow text-myYellow font-bold rounded-md hover:bg-gray-500/20 py-4 text-lg'>
                            Copy to Clipboard
                        </button>
                        <button
                            onClick={onClick}
                            className='w-full bg-myYellow text-parentPurple font-bold rounded-md py-4 text-lg'>
                            Generate Again
                        </button>
                    </div>
                </div>
            )}
        </div>
    )
}

export default BlogPost