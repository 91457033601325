import React from 'react'
import logo from './logo.png'

function Header() {
    return (
        <div className='flex flex-col space-y-6 items-center justify-center py-3 lg:w-2/4 md:w-3/4 w-full'>
            <img src={logo} alt="logo" className='w-24' />
            <h1 className='text-3xl font-bold text-myYellow text-center'>Welcome to
                <span className='text-myPurple'>
                    {' '}Generarti.com
                </span>
            </h1>
            <p className='text-myYellow text-lg text-center'>Your Free AI Content Marketing Hub!</p>
            <p className='text-myYellow text-center text-lg'>
                Harness the power of our generative Al tool to create written marketing content in an instant. Just select your campaign, input the details, hit "generate" and let Generarti do the rest.
            </p>
        </div>
    )
}

export default Header


// Welcome to Generarti.com - Your Free AI Content Marketing Hub!

// Harness the power of our generative AI tool for free to craft written marketing content in an instant. Just select your campaign on the right and input the details, hit “generate” and we’ll give you authentic content that tells your brand's story.