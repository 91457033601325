import React from 'react'
import Dropdown from './Dropdown'
import './App.css'
import { OpenAI } from "openai-streams";
import { AlertBox } from './AlertBox';
const apiKey = process.env.REACT_APP_OPENAI

function RadioAd() {
    const [value, setValue] = React.useState('Choose what you want to create')
    const [value1, setValue1] = React.useState("Choose the tone of voice selection")
    // add this to all pages
    const [result, setResult] = React.useState('')
    const [showInput, setShowInput] = React.useState(true)
    const [usrInput, setUsrInput] = React.useState('')
    const [click, setClick] = React.useState(localStorage.getItem('click') || 0)
    
    const messagesArray = [
        {
            role: "system",
            content: ` Create a radio ad ${value} with ${value1} tone of voice.
            Use the following idea: ${usrInput}
            Be creative and write a ${value} that will engage your audience.`
        }
    ]
    const onClick = async () => {
        localStorage.setItem('value', value)
        localStorage.setItem('value1', value1)
        localStorage.setItem('usrInput', usrInput)
        setClick(click + 1)
        localStorage.setItem('click', click)
        if(click === 1 && (sessionStorage.getItem('uid') === null || sessionStorage.getItem('uid') === undefined)){
            window.location.href = '/login'
            return
        }
            setShowInput(false)
            const stream = await OpenAI(
                "chat",
                {
                    model: "gpt-3.5-turbo",
                    messages: messagesArray,
                },
                { apiKey: apiKey }
            );
            const res = new Response(stream);       // create stream response
            const reader = res.body.getReader();    // get reader from stream
            const decoder = new TextDecoder("utf-8");
            let data = "";
            let msg = [];
            // Read only content from the stream
            while (true) {
                const { done, value } = await reader.read();
                if (done) {
                    break;
                }
                data += decoder.decode(value);
                setResult(data)
            }
    }

    React.useEffect(() => {
        setValue(localStorage.getItem('value'))
        setValue1(localStorage.getItem('value1'))
        setUsrInput(localStorage.getItem('usrInput'))
    }, [])

    const copy = () => {
        navigator.clipboard.writeText(result)
        AlertBox()
    }

    return (
        <div className='flex flex-col items-center w-full h-screen bg-parentPurple px-4'>
            <div className='flex flex-row items-center space-x-4 justify-center h-16'>
                <h1 className='font-bold text-3xl tracking-wide text-myYellow'>
                    Radio Ad
                </h1>
            </div>
            {showInput && (
                <div className='flex flex-col justify-center items-center h-full space-y-4 w-full md:w-3/4 lg:w-2/4'>

                    <select
                        onChange={(e) => setValue(e.target.value)}
                        className='p-4 w-full focus:outline-none rounded-md border border-myYellow bg-myPurple/20 text-myYellow'>
                        <option value="Select Ad length">Choose what you want to create</option>
                        <option value='10 sec'>10 sec</option>
                        <option value='20 sec'>20 sec</option>
                        <option value='30 sec'> 30 sec</option>
                        <option value='40 sec'>40 sec</option>
                        <option value='60 sec'>60 sec</option>

                    </select>
                    <select
                        onChange={(e) => setValue1(e.target.value)}
                        className='p-4 w-full focus:outline-none rounded-md border border-myYellow bg-myPurple/20 text-myYellow'>
                        <option value1="Select tone of voice">Select tone of voice</option>
                        <option value1="Professional/Formal">Professional/Formal</option>
                        <option value1="Conversational">Conversational</option>
                        <option value1="Inspiring">Inspiring</option>
                        <option value1="Humorous/Fun">Humorous/Fun
                        </option>
                        <option value1="Empathetic">Empathetic
                        </option>
                    </select>
                    <textarea rows={8}
                        onChange={(e) => setUsrInput(e.target.value)}
                        placeholder='Describe your product, service, business or idea'
                        className='w-full p-4 rounded-md focus:outline-none focus:ring-2 focus:ring-myYellow/50 transition-all ease-in resize-none bg-myPurple/20 border border-myYellow text-myYellow placeholder:text-myYellow/60' />
                    <button
                        onClick={onClick}
                        className='w-full bg-myYellow text-parentPurple font-bold rounded-md py-4 text-lg'>
                        Generate
                    </button>
                </div>
            )}
            {!showInput && (
                <div className='flex flex-col justify-center items-center h-full space-y-4 w-full md:w-3/4 lg:w-2/4'>
                    <h1 className='text-2xl font-bold text-myYellow'>
                        Generated Content
                    </h1>
                    <textarea rows={16}
                        defaultValue={result}
                        className='w-full p-4 rounded-md focus:outline-none focus:ring-2 focus:ring-myYellow/50 transition-all ease-in resize-none bg-myPurple/20 border border-myYellow text-myYellow placeholder:text-myYellow/60'
                        value={result} />
                    <div className='w-full flex flex-row items-center space-x-4 justify-center'>
                        <button
                            onClick={copy}
                            className='w-full border border-myYellow text-myYellow font-bold rounded-md hover:bg-gray-500/20 py-4 text-lg'>
                            Copy to Clipboard
                        </button>
                        <button
                            onClick={onClick}
                            className='w-full bg-myYellow text-parentPurple font-bold rounded-md py-4 text-lg'>
                            Generate Again
                        </button>
                    </div>
                </div>
            )}
        </div>
    )
}

export default RadioAd